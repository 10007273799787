import React from 'react';
import { PencilSquareIcon, PhotoIcon } from '@heroicons/react/24/outline';
// @ts-ignore
import { defineMessages } from 'react-intl.macro';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Loading from '../Loading';

const messages = defineMessages({
  ImageButtonChangeImage: {
    id: 'ImageInput.ImageButtonChangeImage',
    defaultMessage: 'Change Image',
  },
});

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  image?: string;
  onClick: () => void;
  avatar?: boolean;
  hasIcon?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
} & WrappedComponentProps;

const ImageButton = (props: Props) => {
  const {
    image,
    onClick,
    avatar,
    hasIcon,
    isLoading,
    children,
    intl: { formatMessage },
  } = props;

  const hasImage = !!image;

  return (
    <div
      className={classNames(
        'relative inline-flex w-full h-full',
        'items-center justify-center',
        avatar
          ? 'rounded-full border border-poltio-blue-600'
          : 'rounded shadow-sm border-2 border-dashed',
        'focus:outline-none border-gray-500 text-xs font-medium text-gray-700 bg-gray-100 hover:bg-gray-300 dark:bg-gray-800 dark:border-gray-300 dark:text-gray-200 dark:hover:bg-gray-700'
      )}
    >
      <button
        className={classNames(
          'relative overflow-hidden w-full h-full',
          avatar ? 'rounded-full' : ''
        )}
        onClick={onClick}
        style={{
          backgroundImage: hasImage ? `url('${image}')` : '',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'local',
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
        }}
      >
        <div className="grid grid-rows-1">
          {isLoading && (
            <div className="absolute top-0 h-full w-full flex items-center justify-center backdrop-blur-sm">
              <Loading style={{ padding: '0' }} />
            </div>
          )}
          {!hasImage && !isLoading && (
            <>
              <div className="flex justify-center">
                <PhotoIcon className="h-10 w-10" aria-hidden="true" />
              </div>
              {!!children && children}
            </>
          )}
          {hasImage && (
            <div className="h-full w-full opacity-0 hover:opacity-100">
              <div className="absolute bottom-0 mt-auto w-full h-full">
                <div className="h-full bg-black opacity-70" />
                <div className="absolute top-0 w-full h-full flex items-center justify-center text-white text-base font-semibold">
                  {formatMessage(messages.ImageButtonChangeImage)}
                </div>
              </div>
            </div>
          )}
        </div>
      </button>
      {hasImage && hasIcon && (
        <button
          className="absolute -top-1.5 -right-2 bg-gray-200 rounded-full h-8 w-8 z-20"
          onClick={onClick}
        >
          <PencilSquareIcon className="mx-auto h-6 w-6" aria-hidden="true" />
        </button>
      )}
    </div>
  );
};

export default injectIntl(ImageButton);
